import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/login";
import UrbanArea from "@/views/urbanArea/urbanArea.vue";
import proPage from "@/views/provincePage/proPage.vue";
import BaseLayout from "@/components/BaseLayout.vue";

const routes = [
  {
    path: "/",
    redirect: "/login", // 根路径重定向到登录页面
  },
  {
    path: "/",
    component: BaseLayout, // 公共框架
    children: [
      {
        path: "pcpage",
        name: "PcPage",
        component: () => import("@/views/pcPage/pcPage.vue"), //子页面1
        meta: { requiresAuth: true },
      },
      {
        path: "account",
        name: "AccIndex",
        component: () => import("@/views/account/accIndex.vue"), //子页面2
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/propage",
    name: "proPage",
    component: proPage,
    meta: { requiresAuth: true },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/urbanarea/",
    name: "UrbanArea",
    component: UrbanArea,
    props: (route) => ({ area: route.query.area || "未知区域" }),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 历史模式
  routes,
});

// 前置守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");

  // 对需要登录的页面进行处理
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

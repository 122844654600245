import { createApp } from "vue";
import App from "./App.vue";
import router from "./routers";
import store from "./store";
import axios from "axios";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./styles/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";

// 创建 Vue 应用
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const http = axios.create({
  // 设置请求超时时间为 30 秒（30000 毫秒）
  timeout: 60000,
});
// 配置 Axios
app.config.globalProperties.$http = http;

// 使用路由和状态管理
app.use(router);
app.use(store);
app.use(ElementPlus, {
  locale: zhCn,
});
// 挂载应用
app.mount("#app");

import axios from "../utils/axiosInstance";

export const getKeyword = async () => {
  try {
    const response = await axios.get("/api/getKeywords");
    return response;
  } catch (error) {
    console.error("Error getKeyword data:", error);
    throw error;
  }
};

export const deleteKeywords = async (ids) => {
  try {
    const response = await axios.post("/api/deleteKeywords", { ids });
    return response;
  } catch (error) {
    console.error("Error deleteKeywords:", error);
    throw error;
  }
};

export const insertKeywords = async (type, keywords) => {
  try {
    const response = await axios.post("/api/insertKeywords ", {
      type,
      keywords,
    });
    return response;
  } catch (error) {
    console.error("Error deleteKeywords:", error);
    throw error;
  }
};

export const updateType2 = async (id, type_2) => {
  try {
    const response = await axios.post("/api/updateType2", { id, type_2 });
    return response;
  } catch (error) {
    console.error("Error updating ver:", error);
    throw error;
  }
};

export const getDistrict = async () => {
  try {
    const response = await axios.get("/api/getDistrict");
    return response.data;
  } catch (error) {
    console.error("Error getDistrict:", error);
    throw error;
  }
};

export const filterAllData = async (params) => {
  try {
    const response = await axios.post("/api/fetchData", params);
    return response;
  } catch (error) {
    console.error("Error filterAllData:", error);
    throw error;
  }
};

export const filterData = async (params) => {
  try {
    const response = await axios.post("/api/filterData", params);
    return response;
  } catch (error) {
    console.error("Error filterAllData:", error);
    throw error;
  }
};

export const getProData = async (params) => {
  try {
    const response = await axios.post("/api/getProData", params);
    return response;
  } catch (error) {
    console.error("Error filterAllData:", error);
    throw error;
  }
};

export const getUrbanArea = async () => {
  try {
    const response = await axios.get("/api/getUrbanArea");
    return response.data;
  } catch (error) {
    console.error("Error getUrbanArea:", error);
    throw error;
  }
};

export const lastUpdateDate = async () => {
  try {
    const response = await axios.get("/api/lastUpdateDate");
    return response.data;
  } catch (error) {
    console.error("Error lastUpdateDate:", error);
    throw error;
  }
};
